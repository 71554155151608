html {
  background-color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  /* This controls body bg color */
  background-color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100% !important;
}

.App-logo-container {
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 0 ;
  margin: 0;
  width: 100%;
}

.App-logo {
  height: 100%;
  pointer-events: none;
}

.page-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
  background-color: transparent;
}

.content-wrap {
  width: 100%;
  flex: 1;
  background-color: transparent;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5vh;
  padding-bottom: 40px;
}

/* Footer styling */
.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 20px 10px;
  background-color: #5c5c5c;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 1000px;
  width: 90%;

  p.legal-line {
    color: rgb(196, 196, 196);
    font-size: 0.8rem
  }
}

/* Signup */
.signup-checkbox-label {
  font-size: 0.8rem;

  a {
    color: #24a540;
    text-decoration: none;
  }
}

@media screen and (max-width: 600px) {

  .App-logo-container {
    height: 15vh;
  }
  
  .footer-content {
    flex-direction: column;
  }
  .container-frame {
    max-width: 90%;
  }
}
